body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  min-height: 100vh;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --text_1: rgba(0, 0, 0, 0.87);
  --text_2: rgba(0, 0, 0, 0.54);
  --text-3: rgba(0, 0, 0, 0.38);

  --bkg_1: #fff;
  --bkg_2: #f5f5f5;

  --spacing_half: 0.5rem;
  --spacing_1: 1rem;

  --elevation_1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

/*** Common Styles ***/

/* Utils */

.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flexGrow {
  flex-grow: 1;
}

.inl-flx {
  display: inline-flex;
}

.pv-1 {
  padding: 1rem 0;
}

.m-half {
  margin: 0.5rem;
}

.mv-1 {
  margin: 1rem 0;
}

.fieldgrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 1rem;
}

/* Elements */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
